import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import Waitlist from "../../components/Waitlist.jsx";
import { YoutubePlayer } from "../../components/YoutubePlayer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Logo from '../../../static/img/fornerds_logo_phone2.png';
import CodingPagePhone from '../../../static/img/CodingPagePhone.png';
import { FormattedMessage, useIntl } from 'react-intl';

import "./style.css";

export const IphonePro = () => {
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);

  const handleClose = () => setShowWaitlistModal(false);
  const handleShow = () => setShowWaitlistModal(true);

  const intl = useIntl();
  const isKorean = intl.locale === 'ko';

  return (
    <div className="iphone-pro">
      <div className="div-2">
        <div className="overlap-group-2">
        <img className="img" alt="Frame" src="/img/frame.svg" />
          <img className="frame-9" alt="Frame" src="/img/frame-1.svg" />
          <img className="frame-10" alt="Frame" src="/img/frame-2.svg" />
          <img className="frame-11" alt="Frame" src="/img/frame-3.svg" />
          <img className="LOGO-MAIN" alt="Logo MAIN" src={Logo} />
          <div className="text-wrapper-12">
            <FormattedMessage id="whyItsCool" defaultMessage="Why it’s cool?" />
          </div>
          <div className="text-wrapper-13">
            <FormattedMessage id="rewardSystem" defaultMessage="Reward System" />
          </div>
          <p className="text-wrapper-14">
            <FormattedMessage id="engageInProjects" defaultMessage="Engage in quest-like projects for a fun. Earn rewards and advance your skills with each completion." />
          </p>
          <div className="frame-12">
            <div className="text-wrapper-15">
              <FormattedMessage id="incentiveDrivenProject" defaultMessage="Incentive-Driven Project" />
            </div>
          </div>
          <div className="text-wrapper-16">
            <FormattedMessage id="continuousLearning" defaultMessage="Continuous Learning" />
          </div>
          <p className="text-wrapper-17">
            <FormattedMessage id="aiAnalyzes" defaultMessage="AI analyzes and assigns tasks that fit your skills and interests, offering a tailored development experience." />
          </p>
          <p className="we-ll-launch-a-2">
            <FormattedMessage id="launchMessagePart1" defaultMessage="We’ll launch a platform with a huge number of features that you will definitely like." />
            <br /><br />
            <FormattedMessage id="launchMessagePart2" defaultMessage="And now you can become the very first user and get a god mode absolutely free." />
          </p>
          <div className="frame-13">
            <div className="text-wrapper-18">
            <FormattedMessage id="neverStopCoding" defaultMessage="Never Stop Coding" />
            </div>
            <div className="text-wrapper-19">
            <FormattedMessage id="codeEarnLearn" defaultMessage="Code, Earn, Learn" />
            </div>
          </div>
          <div className="frame-14">
            <img className="bxs-user-circle-2" alt="Bxs user circle" src="/img/bxs-user-circle-2.svg" />
          <div className="bxs-user-circle-3" onClick={handleShow}>
            <img className="ph-smiley-wink-fill" alt="Ph smiley wink fill" src="/img/ph-smiley-wink-fill.svg" />
            <div className="text-wrapper-20">Join beta</div>
          </div>
          </div>
          <div className="frame-15">
            <div className="frame-16" onClick={handleShow}>
              <div className="text-wrapper-21">
                <FormattedMessage id="yourEmail" defaultMessage="your email" />
              </div>
              <img className="akar-icons-arrow-2" alt="Akar icons arrow" src="/img/akar-icons-arrow-right.svg" />
            </div>
            <p className="text-wrapper-22">
              <FormattedMessage id="weReHonest" defaultMessage="We’re really honest guys and promise not to send you ads. We will write only once when our platform is ready." />
            </p>
          </div>
          <img className="frame-17" alt="Frame" src={CodingPagePhone} />
        </div>
        <div className="frame-18">
          <div className="text-wrapper-15">
            <FormattedMessage id="aiDrivenTaskMatching" defaultMessage="AI-driven Task Matching" />
          </div>
        </div>
        <YoutubePlayer className="youtube-player-instance" />

        <Modal show={showWaitlistModal} onHide={handleClose} className={isKorean ? "dark-modal dark-modal-ko" : "dark-modal"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="joinOurWaitlist" defaultMessage="Join our waitlist" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={isKorean ? "dark-modal dark-modal-ko" : "dark-modal"}>
            <Waitlist />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer className="footer-3" divClassName="footer-2" frame="/img/frame-34417-2.svg" weAreJustStartingClassName="design-component-instance-node" />
        <div className="frame-19" onClick={handleShow}>
          <div className="frame-16">
            <div className="text-wrapper-23">
              <FormattedMessage id="yourEmail" defaultMessage="your email" />
            </div>
            <img className="akar-icons-arrow-2" alt="Akar icons arrow" src="/img/akar-icons-arrow-right.svg" />
          </div>
          <p className="text-wrapper-22">
            <FormattedMessage id="weReHonest" defaultMessage="We’re really honest guys and promise not to send you ads. We will write only once when our platform is ready." />
          </p>
        </div>
        <div className="frame-20">
          <div className="text-wrapper-18">
          <FormattedMessage id="neverStopCoding" defaultMessage="Never Stop Coding" />
          </div>
          <div className="text-wrapper-19">
          <FormattedMessage id="codeEarnLearn" defaultMessage="Code, Earn, Learn" />
          </div>
        </div>
      </div>
    </div>
  );
};
