import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useNavigate, useLocation } from 'react-router-dom';
import { Desktop } from './screens/Desktop';
import { IphonePro } from './screens/IphonePro';
import { IpadPro } from './screens/IpadPro';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import messages_en from './locales/en.json';
import messages_ko from './locales/ko.json';
import messages_ja from './locales/ja.json';
import messages_zh from './locales/zh.json';
import messages_vi from './locales/vi.json';
import messages_ne from './locales/ne.json';
import messages_es from './locales/es.json';
import messages_fr from './locales/fr.json';
import messages_hi from './locales/hi.json';
import messages_ru from './locales/ru.json';
import { IntlProvider } from 'react-intl';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-10ZTPTQQ6R'; // 실제 추적 ID로 대체하세요.
ReactGA.initialize(TRACKING_ID);

const messages = {
  'en': messages_en,
  'ko': messages_ko,
  'ja': messages_ja,
  'zh': messages_zh,
  'vi': messages_vi,
  'ne': messages_ne,
  'es': messages_es,
  'fr': messages_fr,
  'hi': messages_hi,
  'ru': messages_ru
};

// 사용자의 디바이스 타입에 따른 초기 경로 설정
const getUserDevicePath = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /iphone|android/i.test(userAgent);
  const isTablet = /ipad|tablet/i.test(userAgent);
  if (isMobile) {
    return '/phone';
  // } else if (isTablet) {
  //   return '/tablet';
  } else {
    return '/desktop';
  }
};

const RedirectToDevicePath = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const path = getUserDevicePath();
    navigate(path);
    
    // 유입 경로 추적
    ReactGA.modalview(path);
  }, [navigate]);

  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <RedirectToDevicePath />,
  },
  {
    path: "/desktop",
    element: <Desktop />,
  },
  {
    path: "/phone",
    element: <IphonePro />,
  },
  {
    path: "/tablet",
    element: <IpadPro />,
  },
]);

// 라우터에서 페이지 변경 시 GA에 페이지뷰 이벤트 전송
router.subscribe((state) => {
  ReactGA.pageview(state.location.pathname + state.location.search);
});


const language = navigator.language.split(/[-_]/)[0];  // 브라우저 언어 설정을 사용

export const App = () => {
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <RouterProvider router={router} />
    </IntlProvider>
  );
};
