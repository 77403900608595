{
    "launchMessagePart1": "私たちはたくさんの機能を備えたプラットフォームを立ち上げます。",
    "launchMessagePart2": "私たちの革新的なプラットフォームを最初に体験するためのウェイトリストに参加してください",
    "neverStopCoding": "コーディング止めず",
    "codeEarnLearn": "コード、獲得、学習",
    "weReHonest": "私たちは本当に正直な人たちで、広告を送ることはありません。プラットフォームが準備できたら一度だけ書きます。",
    "whyItsCool": "なぜそれがクールなのか？",
    "incentiveDrivenProject": "インセンティブ駆動プロジェクト",
    "aiDrivenTaskMatching": "AI駆動のタスクマッチング",
    "rewardSystem": "報酬システム",
    "continuousLearning": "継続的な学習",
    "engageInProjects": "楽しいクエストのようなプロジェクトに従事します。各完了で報酬を獲得し、スキルを向上させます。",
    "aiAnalyzes": "AIは、スキルと興味に合ったタスクを分析して割り当て、カスタマイズされた開発体験を提供します。",
    "joinOurWaitlist": "ウェイトリストに参加する",
    "joinOurService": "私たちのプラットフォームに参加する",
    "forOurFirstUsers": "私たちの最初のユーザーのために",
    "yourEmail": "あなたのメール",
    "footerPart1": "私たちはちょうど私たちの旅を始めています、",
    "footerPart2": "しかし、それがクールになると約束します！",
    "beTheFirstToKnow": "私たちが何をしているのか最初に知る。",
    "invalidEmail": "有効なメールアドレスを入力してください。",
    "emailRegistered": "あなたのメールは正常に登録されました！",
    "emailFailed": "メールの保存に失敗しました。",
    "enterEmail": "メールを入力",
    "submit": "提出する"
}
