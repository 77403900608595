import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from 'react-intl';
import "./style.css";

export const Footer = ({ className, frame = "/img/frame-34417.svg", weAreJustStartingClassName, divClassName }) => {
  return (
    <div className={`footer ${className}`}>
      <p className={`we-are-just-starting ${weAreJustStartingClassName}`}>
        <FormattedMessage id="footerPart1" defaultMessage="We are just starting our journey," />
        <br />
        <FormattedMessage id="footerPart2" defaultMessage="but we promise that it will be cool!" />
      </p>
      {/* <img className="frame" alt="Frame" src={frame} /> */}
      <p className={`text-wrapper ${divClassName}`}>
        © All Rights Reserved. Hattl, Inc
      </p>
    </div>
  );
};

Footer.propTypes = {
  frame: PropTypes.string,
};
