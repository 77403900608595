import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Waitlist from "../../components/Waitlist.jsx";
import { Footer } from "../../components/Footer";
import { YoutubePlayer } from "../../components/YoutubePlayer";
import "./style.css";
import userCircle1 from '../../../static/img/bxs-user-circle-2.png';
import userCircle2 from '../../../static/img/bxs-user-circle-1.png';
import userArrow from '../../../static/img/Arrow.png';
import Logo from '../../../static/img/fornerds_logo.png';
import CodingScreen from '../../../static/img/CodingPage.png';
import { FormattedMessage, useIntl } from 'react-intl';

export const Desktop = () => {
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);

  const handleClose = () => setShowWaitlistModal(false);
  const handleShow = () => setShowWaitlistModal(true);

  const [userCircle, setUserCircle] = useState(userCircle1);

  const handleMouseEnter = () => setUserCircle(userCircle2);
  const handleMouseLeave = () => setUserCircle(userCircle1);

  const intl = useIntl();
  const isKorean = intl.locale === 'ko';


  return (
    <div className="desktop">
      <div className="div">
        <div className="overlap-group">
          <div className="overlap">
            <div className="overlap-2">
            <p className={isKorean ? "we-ll-launch-a little-text" : "we-ll-launch-a"}>
              <FormattedMessage id="launchMessagePart1" defaultMessage="We’ll launch a platform with a huge number of features that you will definitely like." />
              <br /><br />
              <FormattedMessage id="launchMessagePart2" defaultMessage="And now you can become the very first user and get a god mode absolutely free." />
            </p>

              <img
                className="charm-chevron-down"
                alt="Charm chevron down"
                src="https://cdn.animaapp.com/projects/65f258a71a0554a46b83cb8f/releases/65f258b55f98439d6659e445/img/charm-chevron-down.svg"
              />
              <div className="frame-2">
              <div className={isKorean ? "text-wrapper-2 frame-2-ko" : "text-wrapper-2"}>
                  <FormattedMessage id="neverStopCoding" defaultMessage="Never Stop Coding" />
                </div>
                <div className={isKorean ? "text-wrapper-3 frame-2-ko" : "text-wrapper-2"}>
                  <FormattedMessage id="codeEarnLearn" defaultMessage="Code, Earn, Learn" />
                </div>
              </div>
              <img
                className="macbook-air"
                alt="Macbook air"
                src={CodingScreen}
              />
            </div>
            <div className="frame-3">
              <div className="frame-4" onClick={handleShow}>
              <div className={isKorean ? "text-wrapper-4 middle-text" : "text-wrapper-4"}>
                  <FormattedMessage id="yourEmail" defaultMessage="your email" />
                </div>
                <img
                  className="akar-icons-arrow"
                  alt="Akar icons arrow"
                  src={userArrow}
                />
              </div>
              <p className={isKorean ? "p weReHonest-text" : "p"}>
                <FormattedMessage id="weReHonest" defaultMessage="We’re really honest guys and promise not to send you ads. We will write only once when our platform is ready."/>
              </p>
            </div>
          </div>
          <img
            className="LOGO-MAIN"
            alt="Logo MAIN"
            src={Logo}
          />
          <div className="frame-5">
            {/* <img
              className="about-imgage"
              alt="Bxs user circle"
              src="https://cdn.animaapp.com/projects/65f258a71a0554a46b83cb8f/releases/65f258b55f98439d6659e445/img/bxs-user-circle@1.5x.png"
            /> */}
          <img
            className="bxs-user-circle clickable-image"
            alt="Bxs user circle"
            src={userCircle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleShow}
          />
          </div>
          <div className="text-wrapper-5">
            <FormattedMessage id="whyItsCool" defaultMessage="Why it’s cool?" />
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-6">
              <FormattedMessage id="incentiveDrivenProject" defaultMessage="Incentive-Driven Project" />
            </div>
          </div>
          <div className="frame-6">
            <div className="text-wrapper-6">
              <FormattedMessage id="aiDrivenTaskMatching" defaultMessage="AI-driven Task Matching" />
            </div>
          </div>
          <div className="text-wrapper-7">
            <FormattedMessage id="rewardSystem" defaultMessage="Reward System" />
          </div>
          <div className="text-wrapper-8">
            <FormattedMessage id="continuousLearning" defaultMessage="Continuous Learning" />
          </div>
          <p className={isKorean ? "text-wrapper-9 engageInProjects-text" : "text-wrapper-9"}>
            <FormattedMessage id="engageInProjects" defaultMessage="Engage in quest-like projects for a fun. Earn rewards and advance your skills with each completion." />
          </p>
          <p className={isKorean ? "text-wrapper-10 aiAnalyzes-text" : "text-wrapper-10"}>
            <FormattedMessage id="aiAnalyzes" defaultMessage="AI analyzes and assigns tasks that fit your skills and interests, offering a tailored development experience." />
          </p>
          <YoutubePlayer className="youtube-player-instance" />
        </div>
        <p className={isKorean ? "text-wrapper-11 text-wrapper-11-ko" : "text-wrapper-11"}>

          <FormattedMessage id="weReHonest" defaultMessage="We’re really honest guys and promise not to send you ads. We will write only once when our platform is ready."  values={{ br: <br /> }} />
        </p>

        
        <Modal show={showWaitlistModal} onHide={handleClose} className={isKorean ? "dark-modal dark-modal-ko" : "dark-modal"}>
        <Modal.Header closeButton>
        <Modal.Title>
              <FormattedMessage id="joinOurWaitlist" defaultMessage="Join our waitlist" />
            </Modal.Title>
                    </Modal.Header>
        <Modal.Body className={isKorean ? "dark-modal dark-modal-ko" : "dark-modal"}>
          <Waitlist />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

        <Footer
          className="footer-instance"
          frame="https://cdn.animaapp.com/projects/65f258a71a0554a46b83cb8f/releases/65f258b55f98439d6659e445/img/frame-34417-1.svg"
        />
        <div className= "frame-7">
          <div className={isKorean ? "text-wrapper-2 frame-7-ko" : "text-wrapper-2"}>
            <FormattedMessage id="joinOurService" defaultMessage="Join our service" />
          </div>
          <div className={isKorean ? "text-wrapper-3 frame-7-ko" : "text-wrapper-3"}>
            <FormattedMessage id="forOurFirstUsers" defaultMessage="for our first users" />
          </div>
        </div>
        <div className="frame-8" onClick={handleShow}>
        <div className={isKorean ? "text-wrapper-4 text-wrapper-4-ko" : "text-wrapper-4"}>
                  <FormattedMessage id="yourEmail" defaultMessage="your email" />
                </div>
         <img
                  className="akar-icons-arrow"
                  alt="Akar icons arrow"
                  src={userArrow}
                />
        </div>
      </div>
    </div>
  );
};
