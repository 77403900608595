{
    "launchMessagePart1": "हामी तपाईंलाई निश्चित रूपमा मन पराउने धेरै सुविधाहरू सहितको प्लेटफर्म सुरु गर्दैछौं।",
    "launchMessagePart2": "हाम्रो नवीन प्लेटफर्म अनुभव गर्न पहिलो मानिसहरू मध्ये हुन अझै पनि हाम्रो प्रतीक्षा सूचीमा सामेल हुनुहोस्।",
    "neverStopCoding": "कोडिङ नरोक्नुहोस्",
    "codeEarnLearn": "कोड, कमाउनु, सिक्नु",
    "weReHonest": "हामी वास्तवमा ईमानदार छौं र तपाईंलाई विज्ञापन पठाउने वाचा गर्दछौं। हाम्रो प्लेटफर्म तयार भएपछि हामी एक पटक मात्र लेख्नेछौं।",
    "whyItsCool": "यो किन चित्त बुझ्दो छ?",
    "incentiveDrivenProject": "प्रोत्साहन प्रेरित परियोजना",
    "aiDrivenTaskMatching": "AI-प्रेरित कार्य मिलान",
    "rewardSystem": "पुरस्कार प्रणाली",
    "continuousLearning": "निरन्तर सिकाइ",
    "engageInProjects": "मजाका लागि प्रोजेक्टहरूमा संलग्न हुनुहोस्। प्रत्येक पूरा हुँदा पुरस्कार प्राप्त गर्नुहोस् र तपाईंको सीपलाई अगाडि बढाउनुहोस्।",
    "aiAnalyzes": "AI ले तपाईंको सीप र चासो अनुसारका कार्यहरू विश्लेषण गरी तोक्ने गर्दछ, अनुकूलित विकास अनुभव प्रदान गर्दछ।",
    "joinOurWaitlist": "हाम्रो प्रतीक्षा सूचीमा सामेल हुनुहोस्",
    "joinOurService": "हाम्रो प्लेटफर्ममा सामेल हुनुहोस्",
    "forOurFirstUsers": "हाम्रो पहिलो प्रयोगकर्ताहरूका लागि",
    "yourEmail": "तपाईंको ईमेल",
    "footerPart1": "हाम्रो यात्रा अभी शुरू भएको छ,",
    "footerPart2": "तर हामी चाहन्छौं कि यो राम्रो हुनेछ!",
    "beTheFirstToKnow": "हामी के गर्दैछौं भनेर पहिलो पटक जान्नुहोस्।",
    "invalidEmail": "कृपया मान्य ईमेल ठेगाना प्रविष्ट गर्नुहोस्।",
    "emailRegistered": "तपाईंको ईमेल सफलतापूर्वक दर्ता गरिएको छ!",
    "emailFailed": "ईमेल बचत गर्न असफल।",
    "enterEmail": "ईमेल प्रविष्ट गर्नुहोस्",
    "submit": "पेश गर्नुहोस्"
}
