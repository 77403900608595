{
    "launchMessagePart1": "Nous allons lancer une plateforme avec un grand nombre de fonctionnalités que vous aimerez certainement.",
    "launchMessagePart2": "Rejoignez notre liste d'attente pour être parmi les premiers à expérimenter notre plateforme innovante.",
    "neverStopCoding": "Ne jamais arrêter de coder",
    "codeEarnLearn": "Coder, Gagner, Apprendre",
    "weReHonest": "Nous sommes vraiment honnêtes et promettons de ne pas vous envoyer de publicités. Nous écrirons seulement une fois que notre plateforme sera prête.",
    "whyItsCool": "Pourquoi est-ce cool ?",
    "incentiveDrivenProject": "Projet motivé par des incitations",
    "aiDrivenTaskMatching": "Appariement des tâches piloté par IA",
    "rewardSystem": "Système de récompenses",
    "continuousLearning": "Apprentissage continu",
    "engageInProjects": "Engagez-vous dans des projets de type quête pour le plaisir. Gagnez des récompenses et améliorez vos compétences à chaque achèvement.",
    "aiAnalyzes": "L'IA analyse et attribue des tâches qui correspondent à vos compétences et intérêts, offrant une expérience de développement sur mesure.",
    "joinOurWaitlist": "Rejoignez notre liste d'attente",
    "joinOurService": "Rejoignez notre plateforme",
    "forOurFirstUsers": "pour nos premiers utilisateurs",
    "yourEmail": "votre email",
    "footerPart1": "Nous commençons juste notre voyage,",
    "footerPart2": "mais nous promettons que ce sera cool !",
    "beTheFirstToKnow": "Soyez le premier à savoir ce que nous faisons.",
    "invalidEmail": "Veuillez entrer une adresse email valide.",
    "emailRegistered": "Votre email a été enregistré avec succès !",
    "emailFailed": "Échec de l'enregistrement de l'email.",
    "enterEmail": "Entrez l'email",
    "submit": "Soumettre"
}
