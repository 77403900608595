import React, { Component } from 'react';
import './style.css';
import { injectIntl, FormattedMessage } from 'react-intl';

class Waitlist extends Component {

  handleSubmit = async (e) => {
    e.preventDefault();
    const { intl } = this.props; // intl 객체 추출
    const email = e.target.email.value;

    // 이메일 형식 검증을 위한 정규 표현식
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // 이메일 형식이 유효하지 않은 경우
    if (!emailRegex.test(email)) {
      alert(intl.formatMessage({ id: 'invalidEmail', defaultMessage: 'Please enter a valid email address.' }));
      return;
    }

    try {
      const response = await fetch('https://waitlist-70a60e5e13d1.herokuapp.com/submit-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert(intl.formatMessage({ id: 'emailRegistered', defaultMessage: 'Your email has been successfully registered!' }));
      } else {
        alert(intl.formatMessage({ id: 'emailFailed', defaultMessage: 'Failed to save email.' }));
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  render() {
    const { intl } = this.props; // intl 객체 추출
    const locale = intl.locale; // 현재 언어 설정
    const additionalClass = locale === 'ko' ? 'ko-style' : ''; // 한국어일 때만 추가할 CSS 클래스

    return (
      <form onSubmit={this.handleSubmit} className={additionalClass}>
        <h3>
          <FormattedMessage id="beTheFirstToKnow" defaultMessage="Be the first to know what we're up to." />
        </h3>
        <div className="mb-3">
          <label htmlFor="email"></label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder={intl.formatMessage({ id: 'enterEmail', defaultMessage: 'Enter email' })}
          />
        </div>
      
        <div className="mb-3">
          <div className="custom-control custom-checkbox">
          </div>
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            {intl.formatMessage({ id: 'submit', defaultMessage: 'Submit' })}
          </button>
        </div>
      </form>
    );
  }
}

export default injectIntl(Waitlist);
