{
    "launchMessagePart1": "我们将推出一个拥有众多功能的平台，您一定会喜欢。",
    "launchMessagePart2": "加入我们的等候名单，成为最先体验我们创新平台的人。",
    "neverStopCoding": "永不停止编码",
    "codeEarnLearn": "编码，赚取，学习",
    "weReHonest": "我们是真诚的人，承诺不会发送广告。我们的平台准备好后会写信给你一次。",
    "whyItsCool": "为什么这很酷？",
    "incentiveDrivenProject": "激励驱动项目",
    "aiDrivenTaskMatching": "AI 驱动的任务匹配",
    "rewardSystem": "奖励系统",
    "continuousLearning": "持续学习",
    "engageInProjects": "参与类似任务的项目以获得乐趣。每完成一次，就能获得奖励并提升你的技能。",
    "aiAnalyzes": "AI 分析并分配适合您的技能和兴趣的任务，提供定制的开发体验。",
    "joinOurWaitlist": "加入我们的等候名单",
    "joinOurService": "加入我们的平台",
    "forOurFirstUsers": "为我们的首批用户",
    "yourEmail": "您的邮箱",
    "footerPart1": "我们的旅程刚刚开始，",
    "footerPart2": "但我们承诺它会很酷！",
    "beTheFirstToKnow": "成为第一个了解我们正在做什么的人。",
    "invalidEmail": "请输入有效的电子邮件地址。",
    "emailRegistered": "您的电子邮件已成功注册！",
    "emailFailed": "保存电子邮件失败。",
    "enterEmail": "输入电子邮件",
    "submit": "提交"
}
