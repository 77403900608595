{
    "launchMessagePart1": "Vamos a lanzar una plataforma con una gran cantidad de características que definitivamente te gustarán.",
    "launchMessagePart2": "Únete a nuestra lista de espera para ser uno de los primeros en experimentar nuestra plataforma innovadora.",
    "neverStopCoding": "Nunca dejes de codificar",
    "codeEarnLearn": "Codifica, Gana, Aprende",
    "weReHonest": "Somos chicos realmente honestos y prometemos no enviarte anuncios. Solo escribiremos una vez cuando nuestra plataforma esté lista.",
    "whyItsCool": "¿Por qué es genial?",
    "incentiveDrivenProject": "Proyecto impulsado por incentivos",
    "aiDrivenTaskMatching": "Emparejamiento de tareas impulsado por IA",
    "rewardSystem": "Sistema de recompensas",
    "continuousLearning": "Aprendizaje continuo",
    "engageInProjects": "Participa en proyectos tipo quest para divertirte. Gana recompensas y avanza tus habilidades con cada finalización.",
    "aiAnalyzes": "La IA analiza y asigna tareas que se ajustan a tus habilidades e intereses, ofreciendo una experiencia de desarrollo a medida.",
    "joinOurWaitlist": "Únete a nuestra lista de espera",
    "joinOurService": "Únete a nuestra plataforma",
    "forOurFirstUsers": "para nuestros primeros usuarios",
    "yourEmail": "tu correo electrónico",
    "footerPart1": "Estamos comenzando nuestro viaje,",
    "footerPart2": "¡pero prometemos que será genial!",
    "beTheFirstToKnow": "Sé el primero en saber lo que estamos haciendo.",
    "invalidEmail": "Por favor, introduce una dirección de correo electrónico válida.",
    "emailRegistered": "¡Tu correo electrónico ha sido registrado con éxito!",
    "emailFailed": "Error al guardar el correo electrónico.",
    "enterEmail": "Introduce el correo electrónico",
    "submit": "Enviar"
}
