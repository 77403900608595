{
    "launchMessagePart1": "Chúng tôi sẽ ra mắt một nền tảng với nhiều tính năng mà bạn chắc chắn sẽ thích",
    "launchMessagePart2": "Tham gia danh sách chờ của chúng tôi để trở thành những người đầu tiên trải nghiệm nền tảng đổi mới của chúng tôi",
    "neverStopCoding": "Không bao giờ ngừng lập trình",
    "codeEarnLearn": "Lập trình, Kiếm tiền, Học hỏi",
    "weReHonest": "Chúng tôi là những người thực sự trung thực và hứa sẽ không gửi quảng cáo cho bạn. Chúng tôi chỉ viết thư một lần khi nền tảng của chúng tôi sẵn sàng",
    "whyItsCool": "Tại sao nó lại cool?",
    "incentiveDrivenProject": "Dự án được thúc đẩy bởi động lực",
    "aiDrivenTaskMatching": "Phân công nhiệm vụ được điều khiển bởi AI",
    "rewardSystem": "Hệ thống phần thưởng",
    "continuousLearning": "Học hỏi liên tục",
    "engageInProjects": "Tham gia vào các dự án giống như nhiệm vụ để có niềm vui. Nhận phần thưởng và nâng cao kỹ năng của bạn với mỗi lần hoàn thành",
    "aiAnalyzes": "AI phân tích và gán nhiệm vụ phù hợp với kỹ năng và sở thích của bạn, cung cấp trải nghiệm phát triển được cá nhân hóa",
    "joinOurWaitlist": "Tham gia danh sách chờ của chúng tôi",
    "joinOurService": "Tham gia nền tảng của chúng tôi",
    "forOurFirstUsers": "dành cho người dùng đầu tiên của chúng tôi",
    "yourEmail": "email của bạn",
    "footerPart1": "Chúng tôi mới chỉ bắt đầu hành trình của mình,",
    "footerPart2": "nhưng chúng tôi hứa sẽ rất tuyệt vời!",
    "beTheFirstToKnow": "Hãy là người đầu tiên biết những gì chúng tôi đang làm",
    "invalidEmail": "Vui lòng nhập địa chỉ email hợp lệ",
    "emailRegistered": "Email của bạn đã được đăng ký thành công!",
    "emailFailed": "Không thể lưu email",
    "enterEmail": "Nhập email",
    "submit": "Gửi"
}
