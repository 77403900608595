import React from "react";
import { Footer } from "../../components/Footer";
import { YoutubePlayer } from "../../components/YoutubePlayer";
import "./style.css";

export const IpadPro = () => {
  return (
    <div className="ipad-pro">
      <div className="div-3">
        <div className="overlap-3">
          <img className="frame-21" alt="Frame" src="/img/frame-4.svg" />
          <img className="frame-22" alt="Frame" src="/img/frame-5.svg" />
          <img className="frame-23" alt="Frame" src="/img/frame-6.svg" />
          <Footer className="footer-4" frame="/img/frame-34417-3.svg" />
          <img className="frame-24" alt="Frame" src="/img/frame-7.svg" />
          <img className="frame-25" alt="Frame" src="/img/frame-8.svg" />
          <img className="frame-26" alt="Frame" src="/img/frame-9.svg" />
          <img className="frame-27" alt="Frame" src="/img/frame-10.svg" />
          <p className="we-ll-launch-a-3">
            We’ll launch a platform with a huge number of features that you will definitely like.
            <br />
            <br />
            And now you can become the very first user and get a god mode absolutely free.
          </p>
          <div className="frame-28">
            <div className="text-wrapper-24">6 free months</div>
            <div className="text-wrapper-25">for our first users</div>
          </div>
          <div className="frame-29">
            <div className="text-wrapper-24">6 free months</div>
            <div className="text-wrapper-25">for our first users</div>
          </div>
          <div className="frame-30">
            <div className="frame-31">
              <div className="text-wrapper-26">your email</div>
              <img className="akar-icons-arrow-3" alt="Akar icons arrow" src="/img/akar-icons-arrow-right.svg" />
            </div>
            <p className="text-wrapper-27">
              We’re really honest guys and promise not to send you ads. We will write only once when our platform is
              ready.
            </p>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-4">
              <div className="body-part">
                <div className="macbook-foot">
                  <div className="rubber-base-wrapper">
                    <img className="rubber-base" alt="Rubber base" src="/img/left-foot-base.png" />
                  </div>
                </div>
                <div className="img-wrapper">
                  <img className="rubber-base" alt="Rubber base" src="/img/rubber-base-1.svg" />
                </div>
                <div className="trackpad-wrapper">
                  <img className="trackpad" alt="Trackpad" src="/img/trackpad.svg" />
                </div>
              </div>
              <img className="screen-part" alt="Screen part" src="/img/CodingPage.png" />
            </div>
          </div>
          <div className="text-wrapper-28">Why it’s cool?</div>
          <div className="frame-32">
            <div className="text-wrapper-29">gameplay-based engine</div>
          </div>
          <div className="frame-33">
            <div className="text-wrapper-29">teammates and friends</div>
          </div>
          <div className="text-wrapper-30">Games</div>
          <div className="text-wrapper-31">Community</div>
          <p className="text-wrapper-32">
            Love Spotify? Now imagine that we can just as well match games to fit your taste. We are already doing it.
          </p>
          <p className="text-wrapper-33">
            Hattl also shows how similar your game tastes are with your friends. And hattl helps find new teammates.
          </p>
          <YoutubePlayer className="youtube-player-2" />
          <p className="text-wrapper-34">
            We’re really honest guys and promise not to send you ads. We will write only once when our platform is
            ready.
          </p>
          <div className="frame-34">
            <div className="text-wrapper-26">your email</div>
            <img className="akar-icons-arrow-3" alt="Akar icons arrow" src="/img/akar-icons-arrow-right.svg" />
          </div>
        </div>
        <img className="LOGO-MAIN-3" alt="Logo MAIN" src="/img/logo-main-1.png" />
        <div className="frame-35">
          <div className="bxs-user-circle-4">
            <img className="img-2" alt="Fluent chat bubbles" src="/img/fluent-chat-bubbles-question-24-filled.svg" />
            <div className="text-wrapper-35">About</div>
          </div>
          <div className="bxs-user-circle-5">
            <img className="img-2" alt="Ph smiley wink fill" src="/img/ph-smiley-wink-fill.svg" />
            <div className="text-wrapper-36">Join beta</div>
          </div>
        </div>
      </div>
    </div>
  );
};
