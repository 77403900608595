{
    "launchMessagePart1": "새로운 영역으로의 커리어 확장을 꿈꾸시나요? ForNerds가 그 가능성을 열어줍니다.",
    "launchMessagePart2": "개발을 하기만 해도 보상을 얻는 특별한 경험을 누려보세요.",
    "neverStopCoding": "코딩의 끝없는 여정",
    "codeEarnLearn": "나의 성장 습관",
    "weReHonest": "저희는 광고 메일을 보내지 않습니다. 서비스가 출시되면 딱 한 번만 출시 메일을 보내드립니다.",
    "whyItsCool": "Why it’s cool?",
    "incentiveDrivenProject": "Incentive-Driven Project",
    "aiDrivenTaskMatching": "AI-driven Task Matching",
    "rewardSystem": "리워드 시스템",
    "continuousLearning": "지속적인 학습",
    "engageInProjects": "프로젝트를 완료할 때마다 보상을 받으며 자신의 스펙을 키우는 재미를 느껴보세요.",
    "aiAnalyzes": "AI가 맞춤형으로 추천하는 작업 과제를 통해, 지속적인 개발과 성장을 경험하세요.",
    "joinOurWaitlist": "함께해주셔서 감사합니다!",
    "joinOurService": "지금 바로 참여해보세요",
    "forOurFirstUsers": "이 기회를 놓치지 마세요!",
    "yourEmail": "사전 등록하기",
    "footerPart1":"우리의 이야기는 이제부터 시작합니다.",
    "footerPart2":"두근거리는 마음으로 함께 할 분을 찾습니다!",
    "beTheFirstToKnow": "저희 플랫폼의 첫번째 유저가 되어보세요.",
    "invalidEmail": "이메일 형식이 유효하지 않습니다.",
    "emailRegistered": "귀하의 이메일이 성공적으로 등록되었습니다!",
    "emailFailed": "이메일 저장에 실패했습니다.",
    "enterEmail": "이메일 입력",
    "submit": "제출"
}
