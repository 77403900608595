{
    "launchMessagePart1": "We’ll launch a platform with a huge number of features that you will definitely like.",
    "launchMessagePart2": "Join our waitlist to be among the first to experience our innovative platform",
    "neverStopCoding": "Never Stop Coding",
    "codeEarnLearn": "Code, Earn, Learn",
    "weReHonest": "We’re really honest guys and promise not to send you ads. We will write only once when our platform is ready.",
    "whyItsCool": "Why it’s cool?",
    "incentiveDrivenProject": "Incentive-Driven Project",
    "aiDrivenTaskMatching": "AI-driven Task Matching",
    "rewardSystem": "Reward System",
    "continuousLearning": "Continuous Learning",
    "engageInProjects": "Engage in quest-like projects for a fun. Earn rewards and advance your skills with each completion.",
    "aiAnalyzes": "AI analyzes and assigns tasks that fit your skills and interests, offering a tailored development experience.",
    "joinOurWaitlist": "Join our waitlist",
    "joinOurService": "Join our platform",
    "forOurFirstUsers": "for our first users",
    "yourEmail": "your email",
    "footerPart1":"We are just starting our journey,",
    "footerPart2":"but we promise that it will be cool!",
    "beTheFirstToKnow": "Be the first to know what we're up to.",
    "invalidEmail": "Please enter a valid email address.",
    "emailRegistered": "Your email has been successfully registered!",
    "emailFailed": "Failed to save email.",
    "enterEmail": "Enter email",
    "submit": "Submit"
}
  