{
    "launchMessagePart1": "Мы запускаем платформу с огромным количеством функций, которые вам наверняка понравятся.",
    "launchMessagePart2": "Присоединяйтесь к нашему списку ожидания, чтобы стать одним из первых, кто испытает нашу инновационную платформу.",
    "neverStopCoding": "Никогда не переставайте программировать",
    "codeEarnLearn": "Программируйте, Зарабатывайте, Учитесь",
    "weReHonest": "Мы действительно честные ребята и обещаем не отправлять вам рекламу. Мы напишем вам только один раз, когда наша платформа будет готова.",
    "whyItsCool": "Почему это круто?",
    "incentiveDrivenProject": "Проект, мотивированный стимулами",
    "aiDrivenTaskMatching": "Сопоставление задач на основе ИИ",
    "rewardSystem": "Система вознаграждений",
    "continuousLearning": "Непрерывное обучение",
    "engageInProjects": "Участвуйте в проектах, похожих на квесты, ради удовольствия. Получайте награды и развивайте свои навыки с каждым выполнением.",
    "aiAnalyzes": "ИИ анализирует и назначает задачи, соответствующие вашим навыкам и интересам, предлагая индивидуальный опыт развития.",
    "joinOurWaitlist": "Присоединяйтесь к нашему списку ожидания",
    "joinOurService": "Присоединяйтесь к нашей платформе",
    "forOurFirstUsers": "для наших первых пользователей",
    "yourEmail": "ваш email",
    "footerPart1": "Мы только начинаем наше путешествие,",
    "footerPart2": "но обещаем, что это будет круто!",
    "beTheFirstToKnow": "Будьте первым, кто узнает, что мы делаем.",
    "invalidEmail": "Пожалуйста, введите действительный адрес электронной почты.",
    "emailRegistered": "Ваш адрес электронной почты успешно зарегистрирован!",
    "emailFailed": "Не удалось сохранить электронную почту.",
    "enterEmail": "Введите электронную почту",
    "submit": "Отправить"
}
