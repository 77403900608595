{
    "launchMessagePart1": "हम एक प्लेटफार्म लॉन्च करने जा रहे हैं जिसमें आपको निश्चित रूप से पसंद आने वाली बहुत सी सुविधाएँ होंगी।",
    "launchMessagePart2": "हमारे नवीन प्लेटफार्म का अनुभव करने वाले पहले लोगों में से एक बनने के लिए हमारी प्रतीक्षा सूची में शामिल हों।",
    "neverStopCoding": "कोडिंग कभी न रोकें",
    "codeEarnLearn": "कोड, कमाई, सीखें",
    "weReHonest": "हम वास्तव में ईमानदार लोग हैं और वादा करते हैं कि आपको विज्ञापन नहीं भेजेंगे। हमारा प्लेटफार्म तैयार होने पर हम केवल एक बार लिखेंगे।",
    "whyItsCool": "यह क्यों अच्छा है?",
    "incentiveDrivenProject": "प्रोत्साहन संचालित परियोजना",
    "aiDrivenTaskMatching": "एआई संचालित कार्य मिलान",
    "rewardSystem": "पुरस्कार प्रणाली",
    "continuousLearning": "निरंतर सीखना",
    "engageInProjects": "मजेदार क्वेस्ट जैसी परियोजनाओं में लगे रहें। हर पूर्णता के साथ पुरस्कार प्राप्त करें और अपने कौशल को आगे बढ़ाएं।",
    "aiAnalyzes": "एआई आपके कौशल और रुचियों के अनुरूप कार्यों का विश्लेषण और आवंटन करता है, एक अनुकूलित विकास अनुभव प्रदान करता है।",
    "joinOurWaitlist": "हमारी प्रतीक्षा सूची में शामिल हों",
    "joinOurService": "हमारे प्लेटफार्म में शामिल हों",
    "forOurFirstUsers": "हमारे पहले उपयोगकर्ताओं के लिए",
    "yourEmail": "आपका ईमेल",
    "footerPart1": "हम अपनी यात्रा अभी शुरू कर रहे हैं,",
    "footerPart2": "लेकिन हम वादा करते हैं कि यह शानदार होगा!",
    "beTheFirstToKnow": "जानें कि हम क्या कर रहे हैं।",
    "invalidEmail": "कृपया एक वैध ईमेल पता दर्ज करें।",
    "emailRegistered": "आपका ईमेल सफलतापूर्वक पंजीकृत हो गया है!",
    "emailFailed": "ईमेल सहेजने में विफल।",
    "enterEmail": "ईमेल दर्ज करें",
    "submit": "सबमिट करें"
}
