/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const YoutubePlayer = ({ videoId, className }) => {
  const youtubeUrl = `https://www.youtube.com/embed/wf4AJ6-l6xA`;
  return (
    <iframe
      className={`youtube-player ${className}`}
      src={youtubeUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};
